import React, { useState, useEffect, useRef } from 'react';
import classes from '../Search/Search.module.css'
import {fetchSuggestions, fetchProspects, fetchProspectsDirect} from '../../api/gets';
import AutoSuggest from './AutoSuggest';
import PageNumComponent from './PageNumComponent';
import LeftSide_Home from '../Advertisement/LeftSide_Home';
import RightSide_Home from '../Advertisement/RightSide_Home';
import BottomSide from '../Advertisement/BottomSide_Home';
import SearchResults from './SearchResults';
import SearchFilter from '../Search/SearchFilter';

const Search = () => {
  const [inputValue, setInputValue] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [occupationId, setOccupationId] = useState('');
  const [addressValue, setAddressValue] = useState('');
  const [salaryMinValue, setSalaryMinValue] = useState('0');
  const [salaryMaxValue, setSalaryMaxValue] = useState('0');
  const [currentPage, setCurrentPage] = useState(0);
  const [autoSearchResults, setAutoSearchResults] = useState([]);
  const [prospectResults, setProspectResults] = useState([]);
  const [resultVisible, setResultVisible] = useState(true);
  const [lastPageReach, setlastPageReach] = useState(false);
  const searchbox = useRef();
  const addressLocInput = useRef();
  const salaryMinInput = useRef();
  const salaryMaxInput = useRef();
  const searchbutton = useRef();

  const pressBackInitialize = () => {
    setInputValue('');
    setOccupationId('');
    setCurrentPage(0);
    setAutoSearchResults([]);
    setProspectResults([]);  
  }
  //**** toggle showing components
  const toggleVisibility = () => {
     setResultVisible(!resultVisible);
     //pressBackInitialize();
  }

  //search textbox
  const handleKeyDown = (event) => {
       if(event.code === 'Tab'){
        //assign 1st value of autoSearchResults to 
        if(autoSearchResults[0] != null){
          setInputValue(autoSearchResults[0].occupationName)
          setSearchValue(autoSearchResults[0].occupationName)
          setOccupationId(autoSearchResults[0].occupationId);
        }       
       }
       else if(event.code === 'Enter'){
        fetchApiData(inputValue);
       }
       if(inputValue.length === 0){
        //pressBackInitialize();
       }
  }
  const handleInputChange = (event) => {
    if(event.key === 'Tab'){
      searchbutton.current.focus();
    }
    setInputValue(event.target.value);
    if(inputValue.length >= 3){
      console.log("Input change:", inputValue);
      fetchApiData(inputValue);
     }
  };
  const handleSearchInputChange = (occupationName, occupationId) => {
    setInputValue(occupationName);
    setSearchValue(occupationName);
    setOccupationId(occupationId);
    setAutoSearchResults([]);
    setProspectResults([]);
    searchbutton.current.focus();
  }
  //search button
  const handleButtonClick = () => {
    setAutoSearchResults([]);
    fetchProspectList(currentPage);
    toggleVisibility(); 
    setInputValue('');
    searchbox.current.focus();
  };
  
  //pagination
      
  const handlePageChangeParent = (currNum) => {
      if(lastPageReach === false && currNum >= 0){
      setCurrentPage(currentPage => currentPage = currNum);
      fetchProspectList(currNum);
    }
  };

  const handleLastPageResetParent = (isLastPage) => {
    setlastPageReach(isLastPage);
  }

  //**** helper functions ****
  //search textbox auto suggest
  const fetchApiData = async () => {
    try{
      const apiResponse = await fetchSuggestions(inputValue);
      setAutoSearchResults(apiResponse);
    }
    catch(error){
     console.error("Error fetching auto suggest data: ", error);
    }
  };

  useEffect(() => {
    if(searchbox.current) searchbox.current.focus();
  }, [currentPage,inputValue]);

  const fetchProspectList = async (currNum) => {
    try{
      let apiResponse;
      setSearchValue(searchbox.current.value);   
     /*  (addressLocInput.current.value === '') ? setAddressValue('') : setAddressValue('');
      (salaryMinInput.current.value === '0') ? setSalaryMinValue('0') : setSalaryMinValue(salaryMinInput.current.value);
      (salaryMaxInput.current.value === '0') ? setSalaryMaxValue('0') : setSalaryMaxValue(salaryMaxInput.current.value); */
      if(occupationId === ''){      
        //apiResponse = await fetchProspectsDirect(searchbox.current.value,addressLocInput.current.value,salaryMinValue,salaryMaxValue,currNum);
        apiResponse = await fetchProspectsDirect(searchbox.current.value,addressLocInput.current.value,salaryMinInput.current.value,salaryMaxInput.current.value,currNum);
      }
      else{
        //apiResponse = await fetchProspects(occupationId,addressLocInput.current.value,salaryMinValue,salaryMaxValue,currNum);
        apiResponse = await fetchProspects(occupationId,addressLocInput.current.value,salaryMinInput.current.value,salaryMaxInput.current.value,currNum);
      }
      
      if(apiResponse.length !== 0){
        setProspectResults(apiResponse);
        setlastPageReach(false);
      }
      else{
        setCurrentPage(currentPage);
        setlastPageReach(true);
      }
    }
    catch(error){
      console.error("Error fetching prospect data", error);
    }
  };
  
  
  return (
    <div>
      {resultVisible && 
      <div>
      <div className={classes.sponsorLeft}> <LeftSide_Home></LeftSide_Home> </div>
      <div className={classes.outerDiv}>
      <div className={classes.searchDiv}>
        <div className={classes.innerSearchDiv}>
          {/* <SearchFilter></SearchFilter> */}
          <div className={classes.locationFilter}>
                <label>Barangay/City: </label>
                <input type="text" placeholder="ex: Mandaue" ref={addressLocInput}></input>
            </div>
            <div className={classes.minFilter}>
                <label>Min Fee: </label>
                <input type="text" placeholder="ex: 350" ref={salaryMinInput}></input>
            </div>
            <div className={classes.maxFilter}>
                <label>Max Fee: </label>
                <input type="text" placeholder="ex: 800" ref={salaryMaxInput}></input>
          </div>
          <div className={classes.searchGroup}>
            <input
            type="text"
            placeholder="Enter text"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            ref={searchbox}
          />         
          <button onClick={handleButtonClick}
          ref={searchbutton}
          >Search</button>        
            <AutoSuggest results={autoSearchResults} onItemSelect={handleSearchInputChange}></AutoSuggest>
            </div>
        </div>               
      </div>     
    </div>
    
<RightSide_Home></RightSide_Home>
<div className={classes.sponsorRight}> <BottomSide></BottomSide> </div>
    </div>
      }
      {!resultVisible && 
      // <Suspense fallback={<div>loading...</div>}>
      <div>
        <SearchResults callToggleVisibility={toggleVisibility} callPressBackInitialize={pressBackInitialize} prospectSearchResults={prospectResults} occupationSearch={searchValue} occupationSearchDirect={searchValue}>       
        </SearchResults>
        <PageNumComponent currentPage={currentPage} handlePageChange={handlePageChangeParent} handlelastPageReset={handleLastPageResetParent} islastPageReach={lastPageReach}/>
      </div>
      // </Suspense>       
      }
    </div>  
  );
};

export default Search;

