import classes from '../Advertisement/BottomSide_Home.module.css';

const BottomSide = () => {
    return (  
        <div className={classes.mainDiv}>
            <span>Sponsors</span>
        </div>
    );
}
 
export default BottomSide;