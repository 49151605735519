import React,{useState, Suspense} from "react";
import ProspectCardDetail from "../Search/ProspectCardDetail";
import {getProspectDetails} from "../../api/gets"
import classes from '../Search/ProspectCard.module.css'

const ProspectCard = ({prospectItem}) => {
    const [showModal, setShowModal] = useState(false);
    const [currentProspect, setCurrentProspect] = useState('');
    const [prospectDetails, setProspectDetail] = useState([]);

    const openModal = () => {
        fetchProspectDetails();
        setShowModal(true);
      };
    
    const fetchProspectDetails = async () => {
        try{
            const apiResponse = await getProspectDetails(prospectItem.id);
            setProspectDetail(apiResponse);
          }
          catch(error){
            console.error("Error fetching prospect data", error);
          }
    }
    return (  
    <div>
        <img src={prospectItem.mainPicURL} alt="https://raw.githubusercontent.com/stantheman21ph/TestSampleData/main/Pictures/Nopic.jpg"/>
        <ul>
        <li>Name: {prospectItem.prospectName}</li>
        <li>Location: {prospectItem.location}</li>
        <li>Contact#: {prospectItem.contactNumber}</li>
        <li>Email: {prospectItem.email}</li>
        </ul>
        
        <button className={classes.button} onClick={openModal}>View More Details</button>
        
        {showModal && 
            <ProspectCardDetail setOpenModal={setShowModal} prospectData={prospectDetails}/>
        }
    </div>);
}
 
export default ProspectCard;